import axios from "axios"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Util from "packs/utils/Util"
import { DateTime, StringUnitLength } from "luxon"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import ActionCable from "actioncable"
import { funcName, Logger, sleep } from "packs/common"
import RoomMessage from "packs/models/RoomMessage"
import router from "packs/pages/link/router"

import RoomStorage from "packs/models/RoomStorage"
import PossibleDate from "packs/models/PossibleDate"
import SubscriptionManager from "packs/models/SubscriptionManager"
import NotificationControl from "packs/utils/NotificationControl"
import RoomTab from "packs/models/RoomTab"
import UserSetting from "packs/models/UserSetting"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Refresher from "packs/models/Refresher"
import LocationTag from "packs/models//LocationTag"
import PlanOption from "packs/models/PlanOption"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import EmailDomain from "packs/models/EmailDomain"
import AddressBook from "packs/models/AddressBook"
import ContactListGroup from "packs/models/ContactListGroup"
import UserFile from "packs/models/UserFile"
import Organization from "packs/models/Organization"
import GroupTag from "./GroupTag"
import { ProgressFinisher, useProgress } from "@marcoschulte/vue3-progress"
import { reactive } from "vue"
import MessageTemplate from "packs/models/MessageTemplate"
import AstagsTab from "packs/models/fetcher/AstagsTab"
import Const from "packs/utils/Const"
import AssignForm from "packs/models/AssignForm"
import CustomNotification from "packs/models/CustomNotification"

const roomStorage = new RoomStorage()

interface RoomManager {
    rooms?: Room[]
    appointments?: Appointment[]
    openRooms: Room[]
    currentRoom?: Room
    currentAppointment?: Appointment
    currentInstantRoom: Room
    currentMessageRoom: Room
    defaultAstag?: AvailableScheduleTag
    astag: AvailableScheduleTag
    chukaiAstag: AvailableScheduleTag
    astags: AvailableScheduleTag[]
    shared_astags: AvailableScheduleTag[]
    wsConnection?: ActionCable.Connection
    memberDicPerRoom: { [key: string]: { [key: string]: RoomMember } }
    messagesPerRoom: any
    currentRoomMessages?: RoomMessage[]
    cable?: any
    currentAstagStatus?: string
    didConnectCal?: boolean
    did_connect_ms_cal: boolean
    did_connect_google_cal: boolean
    can_use_google_meet: boolean
    can_use_teams: boolean
    can_use_zoom: boolean
    calendar_account_name: string
    support_waaq: boolean
    tutorial_phase?: number
    contact_list_registration_type: string
    contact_list_expires_in_days: string
    confirmed_new_message: boolean
    confirmed_connect_cal: boolean
    confirmed_quick_vote_tutorial: boolean
    plan_option: PlanOption
    userInfo?: RoomMember
    user_setting: UserSetting
    organization: Organization
    fixed_this_month?: any[]
    fixed_last_month?: any[]
    fixed_owner_this_month?: any[]
    fixed_owner_last_month?: any[]
    namespace: string
    logo_url: string
    roomTabs: {}
    publishedNewRooms: any[]
    pdm: any
    refresher: any
    default_location: LocationTag
    showPlanOptionModalWithName: string
    email_domains: EmailDomain[]
    user_groups: any[]
    changeable_user_groups: any[]
    can_use_email_domain: boolean
    email_name: string
    hide_filled_fields: boolean
    address_book: AddressBook
    templates: MessageTemplate[]
    userFiles: UserFile[]
    loctags: LocationTag[]
    locationNames: string[]
    titles: string[]
    nextActionInRoomsDic: any
    isResetFormForOwnerUg: boolean
    guestKey: string
    downloadableFiles: UserFile[]
    my_notifications: any
    groupTags: GroupTag[]
    tabGroupTags: GroupTag[]
    progresses: ProgressFinisher[]
    astagsTab: AstagsTab
    sharedLocationsDic: { [key: string]: LocationTag[] }
    invited_user_groups: any[]
    invite_connect_key: string
    aforms: AssignForm[]
    spHeadersMenu: string[]
    spSelectTab: string
    notifications: any
    noti_available_events: any

    getGroupTags(): any
    updateLocalRoomsData(rooms: any[], tabName: string)
    getRoom(...args: any): Promise<Room>
    getRooms(switchName: string, tabName: string): Promise<any> // {Room[], RoomTab}
    getAvailableSchedule(...args: any)
    setMonthsInfo(data: any)
    setUserInfo(userInfo: RoomMember)
    hasAsTag(): boolean
    setupWebSocket()
    handshakeChannel()
    getMessages(room: Room, page: number)
    updateCurrentInfo(room: Room, astag_id?: string)
    updateMessageRoom()
    resetCurrentInfo()
    setAstag(...args)
    setAppo(appo: Appointment)
    getParentRoom(room: Room): Room
    currentMember(roomId: string): RoomMember
    handleWsEventForMarkAsRead(data: any): void
    handleWsEventForNewMessage(data: any): void
    handleWsEventForCreatedDownloadableFile(data: any): void
    updateFav(room: Room): void
    updateRoomTabs(rooms: Room[]): void
    updateNewMessage(roomMessages: RoomMessage[], message: RoomMessage, parentId: string): void
    handleWsEventForPublishParentRoom(data: any): void
    handleWsEventForUpdateParentRoom(data: any): void
    deleteFromDraftTab(room: Room): void
    deleteFromPublicTab(room: Room): void
    setNewRoom(room: Room)
    fetchRoomTab(uf: string, tabname: string): RoomTab
    // updateOpenRooms(roomId: string): void
    getAllAstags(...args): AvailableScheduleTag[]
    addNewAstag(): AvailableScheduleTag
    selectAstag(astags: AvailableScheduleTag[], selectAstag: AvailableScheduleTag): void
    changeDefaultAstag(astag: AvailableScheduleTag): void
    getAstagDetail(astagId: string): Promise<AvailableScheduleTag>
    canUseOption(optionName: string, setNameIfUnable: boolean): boolean
    getAddressBooks(wantNext: boolean): Promise<RoomMember[]>
    getTemplates(): Promise<MessageTemplate[]>
    getTitles(): Promise<string[]>
    getContactListGroups(): Promise<ContactListGroup[]>
    getUgMembers(): Promise<RoomMember[]>
    getLocations(): any
    getLocationNames(): Promise<string[]>
    findUserGroupSetting(): any
    startProgress()
    endProgress()
    isProgressing(): boolean
    updateAstagsInfoWithTab(tab: AstagsTab)
    redirectTopIfChukaiFreePlan(...args)
    getAllAssignForms()
    getAformDetail(aformId: string)
    designType(): string
    getNotifications(): Promise<any>
}

//  データストア
let _RoomManager: RoomManager = reactive({
    rooms: null, // Rooms
    appointments: [],
    openRooms: [],

    /**
     * 以下 Roomの操作に利用します。
     */

    currentRoom: null,
    currentAppointment: null,
    currentInstantRoom: null,
    currentMessageRoom: null,
    defaultAstag: null, // デフォルトのastag

    astag: null, // この調整ページで利用するastag（astagsの中から取得します）
    chukaiAstag: null, // 仲介会社が不参加時のastag.
    astags: null, // すべてのastagを保持.
    shared_astags: null, // 共有を受けているastagを保持.

    /**
     * 以下 Messageの操作に利用します。
     */

    wsConnection: null, // WebSockett Connection

    memberDicPerRoom: {}, // room_id, member_id をkeyとしたメンバ情報を保持します.

    messagesPerRoom: {}, // room_idをkeyとしたルームごとにメッセージを保持します.

    currentRoomMessages: [],

    cable: null,

    /**
     * 以下 Astagの操作に利用します。
     */

    /**
     * #/available_scheduleページに遷移してくる際にステータスを記憶させます。
     * 1. チューカイのroom作成時: chukaiCreate => 保存して連携 => 保存後ルーム作成画面またはメッセージ画面に戻る,
     * 2. messagesのヘッダー右上から更新: edit => 調整カレンダーを更新
     */
    currentAstagStatus: "",

    // カレンダー連携しているか（していない場合、カレンダー連携ボタンを表示）
    didConnectCal: false,
    did_connect_ms_cal: false,
    did_connect_google_cal: false,
    can_use_google_meet: false,
    can_use_teams: false,
    can_use_zoom: false,
    calendar_account_name: null,

    // グループ設定
    support_waaq: false,
    plan_option: null,
    contact_list_registration_type: null,
    contact_list_expires_in_days: null,

    // ログインしているか.（していない場合、使い方説明付きモーダル表示）
    tutorial_phase: -10,
    confirmed_new_message: false,
    confirmed_connect_cal: false,
    confirmed_quick_vote_tutorial: false,

    // ログインする前のユーザー情報の確認で利用します。
    userInfo: null,
    user_setting: null,
    organization: null,

    fixed_this_month: null,
    fixed_last_month: null,
    fixed_owner_this_month: null,
    fixed_owner_last_month: null,

    // namespace
    namespace: null,

    // 企業が設定したロゴURL
    logo_url: null,

    /**
     * ルームタブ関係 {active: RoomTab} 最初にDictionaryで作成しておく.
     * all / active / active_in_progress / fixed / closed / unread / favorite
     */
    roomTabs: null,

    /**
     * 新規の調整ページが作成された場合、画面の更新をしません。「更新」ボタンが押されたら、public_idベースで一度に取得してきます。
     */
    publishedNewRooms: [],

    /**
     * PDMモデルに移行.
     * possibleDates PossibleDateを配列で持ちます,
     * jpFormats 日本語表記の開始~終了時間を記載したものを配列で持ちます,
     * sharedMembers その部屋でシェアしているRoomMemberの情報を配列で持ちます。
     * possibleDatesPerRoom pdm: {possibleDates: PossibleDate[], jpFormats: [], sharedMembers: [RoomMember]}
     */
    pdm: PossibleDatesManager,

    refresher: Refresher,

    default_location: null,

    showPlanOptionModalWithName: null,

    email_domains: null,
    user_groups: null, // 同一organizationに紐づくugs
    changeable_user_groups: null, // 切り替え可能なugs

    can_use_email_domain: false,
    email_name: null,
    hide_filled_fields: false,

    address_book: null,
    userFiles: null,
    loctags: null,
    locationNames: null,
    titles: null,
    templates: null, //テンプレ

    sharedLocationsDic: {}, // 共有されているlocationTagsを保持します. {key: astag#sid, value: LocationTag[]}

    /**
     * 調整ページ一覧に移動した際に実行したいことを詰め込みます.
     * method:
     * variables: そのメソッドを実行する変数
     */
    nextActionInRoomsDic: null,

    /**
     * ownerUGが別の名前で入力する場合に、入力情報をリセットしないよう覚えておく.
     */
    isResetFormForOwnerUg: false,

    guestKey: null,
    downloadableFiles: null,
    my_notifications: null,

    // 同一UGのもつgroupTags.
    groupTags: null,
    // 自分が設定したタブ.
    tabGroupTags: null,
    progresses: [] as ProgressFinisher[],
    astagsTab: null,

    // current_ugを招待しているUGsと招待してもらうためのkeyを保持します.
    invited_user_groups: null,
    invite_connect_key: null,

    aforms: null,

    // スマホで開いた場合のヘッダーメニュー.
    spHeadersMenu: [],
    spSelectTab: null,

    // カスタム通知情報
    notifications: null,
    noti_available_events: null,

    /**
     * 調整ページを取得します.
     * @param pid [string] ParentRoomIdまたはPublicRoomId
     * @param roomType [string]
     */
    getRoom(pid: string, roomType = `parent_room`, tabName = null, page = null): Promise<Room> {
        let endpoint = `rooms`
        let params = {}
        if (roomType == `public_room`) {
            endpoint = `public_rooms`
            params = {
                tab_name: tabName,
                page: page,
            }
        }
        Logger(`rm.${funcName()} pid:${pid}`)
        return axios
            .get(`${Util.prefixUrl}/${endpoint}/${pid}`, { params: params })
            .then(res => {
                const roomsData = [res.data.room]
                const _rooms = Room.fetchFromJson(roomsData, this.userInfo.user_id)
                const room = _rooms[0]
                // ローカルデータ this.rooms 等をアップデート
                Logger(`rm.${funcName()} RoomManager#getRoom call Room.mergeRooms`)
                this.rooms = Room.mergeRooms(this.rooms ? [...this.rooms] : [], _rooms)
                this.updateLocalRoomsData(_rooms)
                return room
            })
            .catch(err => {
                Logger(`rm.${funcName()} err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    },

    /**
     * 調整ページ一覧を取得します.
     * @param userFilter [string] self / all
     * @param tabName [string]
     * @return Room[],string, string
     */
    getRooms(userFilter: string, tabName: string): Promise<any> {
        Logger(
            `${funcName()} userFilter:${userFilter}, tabName:${tabName}, uf_id:${this.userInfo ? this.userInfo.user_id : null}`
        )
        if (Util.isBlank(this.userInfo)) return Promise.resolve(null)
        /**
         * ルーム一覧のタブ情報をセットします。
         */
        if (!this.roomTabs) this.roomTabs = RoomTab.createAllTabs(this.tabGroupTags)

        /**
         * 既に保存しているタブ情報を確認し、ルーム情報を取得してきます。
         */
        let tab: RoomTab = null
        if (!userFilter || !tabName) tab = this.roomTabs[`all`].active_in_progress

        // Logger(`RoomManager#getRooms this.roomTabs: ${Util.output(this.roomTabs)}`)
        let params = {}
        if (!tab) {
            tab = this.roomTabs[userFilter][tabName] as RoomTab
        }
        // 次がない場合、取得を中止します。
        if (!tab.has_next) return Promise.resolve(null)
        params = RoomTab.getCurrentTabParams(tab)

        let endpoint = `rooms/index`
        if ([`public`, `shared`].includes(tabName)) endpoint = `public_rooms/index`
        if (Util.isPresent(tab.groupTag)) {
            endpoint = `public_rooms/index`
        }

        // const paramsSerializer = qs.stringify(params)
        // Change GET to POST v12.2.0-
        // return axios.get(`${Util.prefixUrl}/${endpoint}`, { params: params }).then(res => {
        return axios.post(`${Util.prefixUrl}/${endpoint}`, params).then(res => {
            let uf = res.data.user_filter
            let tabName = res.data.tab_name
            if (uf === undefined && tabName === undefined) {
                Notice.message = `セッションが切れました。ログインし直してください。`
                location.href = `/`
                return
            }
            if (tabName == `group_tag`) {
                tabName = res.data.group_tag_id
                uf = `all`
            }
            tab = this.roomTabs[uf][tabName]

            Logger(`rm.${funcName()} RoomManager#getRooms  セットするタブ. ${uf} ${tabName}`)
            const newTab = RoomTab.updateCurrentTab(tab, res.data, this.userInfo)
            this.roomTabs[uf][tabName] = newTab
            // Vue.set(this.roomTabs, `[${uf}][${tabname}]`, newTab)
            const rooms = newTab.rooms
            Logger(
                `rm.${funcName()} RoomManager#getRooms タブへのセットが終わりました. ${uf} ${tabName} : has_next: ${
                    newTab.has_next
                }, next_page: ${newTab.next_page}  ${rooms.length}`
            )
            // this.$set(this, this.roomTabs[userFilter][tabName], newTab)
            if (![`draft`, `public`].includes(tabName)) this.updateLocalRoomsData(rooms, tabName)

            return { rooms, tab: newTab }
        })
    },

    /**
     * ローカルの調整ページに関するデータを更新します。
     * @param rooms
     */
    updateLocalRoomsData(rooms: Room[], tabName: string = null) {
        Logger(`rm.${funcName()} RoomManager#updateLocalRoomsData`)
        // this.rooms = rooms

        const roomKey = roomStorage.fetchSelectedRoomKey()
        Logger(`rm.${funcName()} roomKey: ${roomKey}`)

        for (const room of rooms) {
            if (roomKey == room.keyId) {
                this.updateCurrentInfo(room)
            }

            this.messagesPerRoom[room.room_id] = null

            for (const childroom of room.children) {
                if (roomKey == childroom.keyId) {
                    // findCurrentRoom = true
                    this.updateCurrentInfo(childroom)
                }
                // 子ルームの未読・既読情報を更新します
                let memberDic = this.memberDicPerRoom[room.room_id]
                this.memberDicPerRoom[childroom.room_id] = memberDic ? memberDic : {}
                // const _mems = RoomMember.fetchFromJson(childroom.members)
                for (const member of childroom.members) {
                    this.memberDicPerRoom[childroom.room_id][member.user_id] = member
                }
                this.messagesPerRoom[childroom.room_id] = this.messagesPerRoom[childroom.room_id] || {}
            }
        }

        // コピーしないと、同じroomTabの中に、this.roomsが入っていってしまう現象.
        Logger(`rm.${funcName()} RoomManager#updateLocalRoomsData call Room.mergeRooms`)
        this.rooms = Room.mergeRooms(this.rooms ? [...this.rooms] : [], rooms, RoomTab.getSortFuncByTabName(tabName))
    },
    /**
     * 自分のastagを取得してきます。
     */
    getAvailableSchedule(checkAstag = true, parentId?: string): Promise<AvailableScheduleTag> {
        return axios
            .get(`${Util.prefixUrl}/available_schedule_tags`, { params: { parent_room_id: parentId } })
            .then(res => {
                const astagsData = res.data.astags

                Logger(`rm.${funcName()} res.data.user_info: ${Util.output(res.data.user_info)}`)

                let { astag, chukaiAstag, defaultAstag, astags } = AvailableScheduleTag.setAstags(astagsData)
                this.astag = astag
                this.chukaiAstag = chukaiAstag
                this.defaultAstag = defaultAstag
                this.astags = astags

                let org = res.data.organization
                if (Util.isPresent(org)) {
                    this.organization = org
                    this.email_domains = org.email_domains
                    this.user_groups = org.user_groups
                }
                let changeable_user_groups = res.data.changeable_user_groups
                if (changeable_user_groups) {
                    this.changeable_user_groups = changeable_user_groups
                }

                this.can_use_email_domain = res.data.can_use_email_domain
                this.email_name = res.data.email_name
                Logger(`rm.${funcName()} email_domains: ${Util.output(this.email_domains)}`)
                this.resetCurrentInfo()

                if (Util.isPresent(this.astags)) this.selectAstag(this.astags, this.astag)

                this.didConnectCal = res.data.did_connect_cal
                this.did_connect_ms_cal = res.data.did_connect_ms_cal
                this.did_connect_google_cal = res.data.did_connect_google_cal
                this.can_use_google_meet = res.data.can_use_google_meet
                this.can_use_teams = res.data.can_use_teams
                this.can_use_zoom = res.data.can_use_zoom
                this.calendar_account_name = res.data.calendar_account_name
                this.tutorial_phase = res.data.tutorial_phase
                this.confirmed_new_message = res.data.confirmed_new_message
                this.confirmed_connect_cal = res.data.confirmed_connect_cal
                this.confirmed_quick_vote_tutorial = res.data.confirmed_quick_vote_tutorial
                this.user_setting = UserSetting.fetchFromJson(res.data.user_setting)
                this.support_waaq = res.data.support_waaq
                this.namespace = res.data.namespace
                this.logo_url = res.data.logo_url
                this.contact_list_registration_type = res.data.contact_list_registration_type
                this.contact_list_expires_in_days = res.data.contact_list_expires_in_days
                if (res.data.default_location) this.default_location = LocationTag.fetchFromJson([res.data.default_location])[0]

                const userInfoData = res.data.user_info
                this.plan_option = res.data.plan_option as PlanOption
                const uf = RoomMember.fetchFromJson([userInfoData])[0]
                Logger(`rm.${funcName()} user info : ${Util.output(userInfoData)}, tutorial_phase: ${this.tutorial_phase}`)
                this.setUserInfo(uf)

                this.setMonthsInfo(res.data)
                this.my_notifications = res.data.my_notifications

                this.tabGroupTags = res.data.tab_group_tags || []

                this.astagsTab = AstagsTab.createNew(astags, [], res.data.astags_tab_info)

                /**
                 * ルーム一覧のタブ情報をセットします。
                 */
                if (!this.roomTabs) this.roomTabs = RoomTab.createAllTabs()

                if (Const.chukaiFreePlan(this)) {
                    this.invite_connect_key = res.data.invite_connect_key
                    let invitedUgs = res.data.invited_user_groups
                    if (invitedUgs && invitedUgs.length > 0) {
                        // 招待されているUGがある場合
                        this.invited_user_groups = invitedUgs
                    } else {
                        // 招待されていない場合
                        router.push(`/not_invited`)
                        return
                    }
                }

                return this.defaultAstag
            })
            .catch(err => {
                Logger(`rm.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                const res = err.response ? err.response : null
                if (res) {
                    if (res.status == 400) {
                        Notice.message = `セッションが切れました。ログインし直してください。`
                        location.href = `/`
                    }
                }
                return null
            })
    },
    /**
     * ルーム一覧に設置する件数を保存します。
     * @param data
     */
    setMonthsInfo(data: any) {
        this.fixed_this_month = data.fixed_this_month || []
        this.fixed_last_month = data.fixed_last_month || []
        this.fixed_owner_this_month = data.fixed_owner_this_month
        this.fixed_owner_last_month = data.fixed_owner_last_month
        // Logger(`other DATA: ${data.fixed_owner_this_month} ${data.fixed_owner_last_month}`)
    },
    /**
     * オーナーやUserInfoを特定できるAstag取得時か、
     * ゲストでユーザー情報を取得してきた場合にはめて、チャットを取得できるようにします。
     * @param userInfo
     */
    setUserInfo(userInfo: RoomMember) {
        this.userInfo = userInfo
        this.pdm.setUserInfo(this.userInfo)
        if (!this.wsConnection) this.setupWebSocket()
    },
    /**
     * Astagが作成しているかを確認します。
     */
    hasAsTag(): boolean {
        return this.astag
    },
    /**
     * サーバー側とWebSocketコネクションを確立します。
     */
    setupWebSocket() {
        if (Util.isEmbeds()) {
            this.cable = {}
            this.wsConnection = {}
            return
        }
        if (!this.cable) {
            this.cable = ActionCable.createConsumer("/api/ws/cable")
            this.wsConnection = this.handshakeChannel()
            Logger(`<WS>チャネル登録が完了しました`)
        } else {
            Logger(`<WS>setupWebSocket called already setup`)
        }
    },

    /**
     * 持っているルームをサーバー側にルームをサブスクライブします。
     */
    handshakeChannel() {
        const channel = "RoomChannel"
        let connection = this.cable.subscriptions.create(
            { channel: channel, user_id: this.userInfo.user_id },
            {
                connected: data => {
                    Logger(`<WS>connected: ${Util.output(data)}`)
                },
                disconnected: data => {
                    Logger(`<WS>disconnected: ${Util.output(data)}`)
                },
                rejected: data => {
                    Logger(`<WS>rejected: ${Util.output(data)}`)
                },
                received: data => {
                    Logger(`<WS>Eventを受信しました: ${Util.output(data)}`)
                    if (data.publish_parent_room) {
                        this.handleWsEventForPublishParentRoom(data.publish_parent_room)
                    }
                    if (data.update_parent_room) {
                        this.handleWsEventForUpdateParentRoom(data.update_parent_room)
                    }
                    if (data.mark_as_read) {
                        this.handleWsEventForMarkAsRead(data.mark_as_read)
                    }
                    if (data.new_message) {
                        this.handleWsEventForNewMessage(data.new_message)
                    }
                    if (data.created_downloadable_file) {
                        this.handleWsEventForCreatedDownloadableFile(data.created_downloadable_file)
                    }
                },
            }
        )
        return connection
    },
    /**
     * メッセージを取得してきます。
     * @param room [Room]
     */
    getMessages(room: Room, page: number = 1): Promise<any[]> {
        if (!room) return Promise.resolve(null)
        if (room.room_type == `public_room`) return Promise.resolve(null)
        Logger(`rm.${funcName()} roomId:${room.id}, page:${page}`)
        let parentId = room.isParent ? room.id : room.parentRoomId
        if (!parentId) {
            // parentIdがないため、いったん返却します。
            return Promise.resolve(null)
        }

        return axios
            .get(`${Util.prefixUrl}/room_messages`, {
                params: {
                    page: page,
                    room_id: room.room_id,
                    parent_id: parentId,
                },
            })
            .then(res => {
                const messagesData = res.data.messages_dic
                if (messagesData) {
                    let dic = {}
                    Object.entries(messagesData).forEach(([room_id, messagesDic]) => {
                        const _messagesDic: any = messagesDic
                        Logger(`rm.${funcName()} 取得してきた_messagesDic: ${Util.output(_messagesDic)}`)

                        /**
                         * 挿入します。
                         */
                        dic[room_id] = RoomMessage.insertMessagesPerRoom(this.messagesPerRoom, room_id, _messagesDic)

                        if (this.currentRoom && this.currentRoom.room_id === room_id) {
                            this.currentRoomMessages = dic[room_id].messages
                        }
                    })
                    this.messagesPerRoom = dic
                }
                return this.currentRoomMessages
            })
            .catch(err => {
                // Logger(`err: ${err.message} ${Util.output(err.response)}`)
                // NotificationControl.showErrorMessage(err)
                return null
            })
    },

    /**
     * 現在クリックしている調整ページ情報にすべてをアップデートします。
     * @param room [Room] 更新する調整ページ
     * @param astag_id [string] 指定がある場合はそのastagをセット.
     */
    updateCurrentInfo(room: Room, astag_id: string = null) {
        if (!room) return

        Logger(`rm.${funcName()} RoomManager#updateCurrentInfo room:${room?.id}`)
        this.currentRoom = room
        this.updateMessageRoom()
        if (Util.isPresent(this.rooms)) {
            let _room = this.rooms.find(r => r.id == room.id)
            if (_room) _room.progress_status = room.progress_status
        }

        this.currentAppointment = room.current_appointment
        if (Util.isPublic()) return
        if (Util.isPreview()) {
            // プレビュー
            this.astag = (this.astags || []).find(ast => ast.id == room.owner_avaialble_schedule_tag_id)

            Logger(`rm.${funcName()} preview用 this.defaultAstag: ${this.defaultAstag?.id}:${this.defaultAstag?.name}`)
        } else {
            Logger(`rm.${funcName()} astags:${Util.output(this.astags)}`)
            this.astag ||= (this.astags || []).find(ast => ast.rooms && ast.rooms.includes(room.id))

            if (astag_id) {
                this.astag ||= (this.astags || []).find(a => a.id == astag_id)
            }
        }

        roomStorage.saveSelectedParentRoomId(room.id)

        if (Util.isBlank(this.astag)) this.setAstag()
        // roomStorage.save(room)
        // roomStorage.saveAppointment(room.current_appointment)

        Logger(`rm.${funcName()} astagにセット astag:${this.astag?.id}:${this.astag?.name}`)

        // currentRoomMessagesはRoomMessagesに移譲.
        // this.currentRoomMessages = this.messagesPerRoom[room.room_id]
    },
    updateMessageRoom() {
        Logger(`rm.${funcName()} ${this.currentRoom?.id}`)
        if (!this.currentRoom) return

        // if (this.room.room_type == `public_room` && this.rm.currentRoom.room_type == `parent_room`) {
        if (this.currentRoom.room_type == `parent_room`) {
            // ParentRoomに更新します。
            Logger(
                `rm.${funcName()} parentRoomに更新がありました: currentRoom(${this.currentRoom?.id}) room_type:${
                    this.currentRoom?.room_type
                }, currentMessageRoom(${this.currentMessageRoom?.id}) room_type:${this.currentMessageRoom?.room_type}`
            )
            if (Util.isBlank(this.currentMessageRoom) || this.currentMessageRoom.room_type == `public_room`) {
                this.currentMessageRoom = this.currentRoom
            } else if (this.currentMessageRoom.room_id == this.currentRoom.room_id) {
                this.currentMessageRoom = this.currentRoom
            } else {
                Logger(`rm.${funcName()} NotFound`)
                for (let _croom of this.currentRoom.children) {
                    if (this.currentMessageRoom.room_id == _croom.room_id) {
                        Logger(`rm.${funcName()} _croom.room_type:${_croom.room_type}`)
                        this.currentMessageRoom = _croom
                    }
                }

                if (Util.isBlank(this.currentMessageRoom)) {
                    Logger(`rm.${funcName()} Blank currentMessageRoom`)
                    this.currentMessageRoom = this.currentRoom
                }
            }
            Logger(`rm.${funcName()} this.currentMessageRoom:${this.currentMessageRoom?.id}`)
        }
    },
    resetCurrentInfo() {
        this.currentRoom = null
        this.currentAppointment = null
        Logger(`rm.${funcName()} astags.length:${this.astags?.length}`)
        if (Util.isBlank(this.astags)) return
        this.setAstag()
        Logger(`rm.${funcName()} resetCurrentInfo: astagをセットしました: ${this.astag?.name}`)
    },
    setAstag(defaultType = 1) {
        this.astag = Util.isPresent(this.defaultAstag) ? this.defaultAstag : this.astag
        Logger(
            `rm.${funcName()} 最初1 defaultType:${defaultType} astag:${this.astag?.id}:${this.astag?.name}, astags[${
                this.astags?.length
            }]:${Util.output(this.astags)}`
        )
        if (Util.isBlank(this.astag)) {
            let _astag = (this.astags || []).find(a => a.type == defaultType)
            Logger(`rm.${funcName()} 最初2 astag:${_astag?.id}:${_astag?.name}`)
            if (_astag) {
                this.astag = _astag
                Logger(`rm.${funcName()} SET find MY astag:${this.astag?.id}:${this.astag?.name}`)
            } else {
                this.astag = AvailableScheduleTag.createDefaultAstag()
                Logger(`rm.${funcName()} SET create default astag:${this.astag?.id}:${this.astag?.name}`)
            }
        }
    },
    setAppo(appo: Appointment) {
        Logger(`rm.${funcName()} ${Util.output(appo)}`)
        this.currentAppointment = appo
        this.currentRoom.current_appointment = appo
        roomStorage.save(this.currentRoom)
        roomStorage.saveAppointment(appo)
    },
    /**
     * 子ルームから親ルームを取得します。
     * @param room
     */
    getParentRoom(room: Room): Room {
        let _room = Room.find(this.rooms, "#", room.room_id)
        if (_room) return _room
        return null
    },
    /**
     * 現在アクセス者の指定ルームにおけるRoomMemberオブジェクトを返却します。
     * @param roomId
     */
    currentMember(roomId: string): RoomMember {
        // const memberDic = this.memberDicPerRoom[roomId]
        let _room = Room.find(this.rooms, "#", roomId)
        if (!_room) return null
        // if (!memberDic) return null
        // const currentUserId = this.userInfo.user_id
        // const members: RoomMember[] = Object.values(memberDic)
        // return members.find(mem => mem.user_id === currentUserId)
        // return memberDic[this.userInfo.user_id]
        return _room.members.find(m => m.user_id == this.userInfo.user_id)
    },

    /**
     * 誰かが既読にした WSイベント を処理します。
     * @param data
     */
    handleWsEventForMarkAsRead(data: any): void {
        Logger(`<WS>handleWsEventForMarkAsRead: ${Util.output(data)}`)
        // Logger(`memberDicPerRoom 1: ${Util.output(this.memberDicPerRoom)}`)
        // const memberDic = this.memberDicPerRoom[data.room_id] || {}
        let _room = Room.find(this.rooms, `#`, data.room_id)
        if (!_room) return
        let _member = _room.members.find(m => m.user_id == data.user_id)
        // let member: RoomMember = memberDic[data.user_id] ? { ...memberDic[data.user_id] } : {}

        //{"room_id":"5e0411702643ec24ed37d1c0","member_id":"5e0411702643ec24ed37d1c2","user_id":"5d8a9e552643ec0a92281560","last_read_message_id":"5eb1243964516c1aa93b34ee","last_read_message_created_at":1588667449,"last_read_at":1588668553}
        // const updatedMember: RoomMember = Object.assign(member, data)
        if (!_member) return
        // let member = { ..._member } as RoomMember
        // Object.assign(member, data)
        // _member = member

        _member.last_read_message_id = data.last_read_message_id
        _member.last_read_message_created_at = data.last_read_message_created_at
        _member.last_read_at = data.last_read_at

        Logger(`rm.${funcName()} memberの既読時間を更新します。 :${Util.output(_member)}`)

        // memberDic[data.user_id] = member
        // this.memberDicPerRoom[data.room_id][data.user_id] = member
        // Logger(`memberDicPerRoom 2: ${Util.output(this.memberDicPerRoom)}`)
        // this.updateOpenRooms(data.room_id)
    },

    /**
     * 新着メッセージ WSイベント を処理します。
     * @param data
     */
    handleWsEventForNewMessage(data: any): void {
        Logger(`<WS>handleWsEventForNewMessage: ${Util.output(data)}`)
        const mess = RoomMessage.fetchFromJson([data.message])
        let message: RoomMessage = mess ? mess[0] : null
        const parentId = message.parent_id
        let dic = this.messagesPerRoom[message.room_id]
        if (!dic) {
            // ルームが存在していないので、ルーム情報を取得した上で入れ込みます。
            Logger(`rm.${funcName()} ルームが存在していないので、ルーム情報を取得した上で入れ込みます。`)
            let _room = Room.find(this.rooms, "#", message.room_id)
            this.getMessages(_room).then(currentRoomMessages => {
                Logger(`rm.${funcName()} 最新のメッセージを再取得しましたのでご安心ください。 ${currentRoomMessages}`)
                dic = this.messagesPerRoom[message.room_id]
                let roomMessages = Util.isPresent(dic) ? dic.messages || [] : []
                if (data.message.system_type == 60) {
                    let fileId = data.message.deleted_file.file_id
                    RoomMessage.deleteFileInMessages(roomMessages, fileId)
                }
                this.updateNewMessage(roomMessages, message, parentId)
            })
        } else {
            let roomMessages = dic ? dic.messages || [] : []
            if (data.message.system_type == 60) {
                let fileId = data.message.deleted_file.file_id
                RoomMessage.deleteFileInMessages(roomMessages, fileId)
            }

            this.updateNewMessage(roomMessages, message, parentId)
        }
    },
    /**
     * ダウンロードファイルが作成された時点で取得します.
     * @param data
     */
    handleWsEventForCreatedDownloadableFile(data: any) {
        Logger(`<WS>handleWsEventForCreatedDownloadableFile data:${Util.output(data)}`)
        let files = this.downloadableFiles || []
        let createdFile: UserFile = data.user_file

        let file = files.find(f => f.file_id == createdFile.file_id)
        if (file) {
            files = files.filter(f => f.file_id != createdFile.file_id)
            files.unshift(createdFile)
        } else {
            files.unshift(createdFile)
        }
        this.downloadableFiles = files
    },
    updateFav(room: Room) {
        let _room = Room.find(this.rooms, room.id, "#")
        if (_room) {
            _room.starred = room.starred
            this.updateRoomTabs(null)
        } else {
            // pub_roomのスター.
            let roomTab = this.roomTabs[`all`][`public`]

            _room = (roomTab.rooms || []).filter(r => r.id == room.id)
            if (_room) {
                _room.starred = room.starred
            }
        }
    },
    updateRoomTabs(rooms: Room[]): void {
        Logger(`rm.${funcName()} updateRoomTabs called.`)
        this.roomTabs = RoomTab.sortAutomatically(this.roomTabs, rooms || this.rooms, this.userInfo)
    },
    /**
     * 送信中のメッセージ情報を削除し、取得してきたメッセージ情報を入れます。
     * @param roomMessages 既に取得済みのメッセージ
     * @param message 今回受信した新しいメッセージ
     * @param parentId 取得してきたメッセージのparent_id
     */
    updateNewMessage(roomMessages: RoomMessage[], message: RoomMessage, parentId: string) {
        const roomMessagesIds = roomMessages.map(m => m.id) || []
        // 未送信のメッセージを削除します。
        roomMessages.forEach((el, index) => {
            if ((el.id == "sending" || el.id == "unsent") && el.message == message.message) {
                roomMessages.splice(index, 1)
            }
        })
        if (!roomMessagesIds.includes(message.id)) {
            const lastMessage: RoomMessage = roomMessages[roomMessages.length - 1]
            // const lastMessage: RoomMessage = RoomMessage.fetchFromJson([roomMessages[roomMessages.length - 1]])[0]
            /**
             * この前に取得していた最後のメッセージと同じ日付だったら、start_of_the_dayをfalseにして日付を表示させない.
             */
            if (lastMessage && DateTime.fromSeconds(lastMessage.created_at).day == DateTime.fromSeconds(message.created_at).day) {
                message.start_of_the_day = false
            }
            roomMessages.push(message)

            // 自分ではない場合にバッジを更新します。
            // TODO: user_id undefinedになることがある.
            if (message.is_system || message.owner.user_id != this.userInfo.user_id) {
                //
                // バッジの更新
                //
                let room = Room.find(this.rooms, "#", message.room_id)
                if (room) {
                    // オーナーでなく、かつ全体権限をもらっていない場合は、更新しません.
                    if (!room.is_owner && !UserPermissionsOption.permitDisplay(this.userInfo, `rooms`, `rooms_all_tab`)) {
                        return
                    }
                    room.unread_num++
                    Logger(`rm.${funcName()} メッセージのバッジをカウントアップしました Parent。 ${room.unread_num} + 1`)
                    // let roomTab: RoomTab = this.roomTabs[`all`][`unread`]
                    // Room.mergeRooms(roomTab.rooms, [room])
                    this.updateRoomTabs()
                }

                NotificationControl.createAndPushMessage(message)
            }
        }

        // 投稿者自身がメンバならLocalの読み込み時刻を更新します
        // if (message.member_id && message.member_id === message.owner.user_id) {
        //     this.memberDicPerRoom[message.room_id][message.owner.user_id] = message.owner
        // }
        let _room = Room.find(this.rooms, "#", message.room_id)
        if (_room) {
            Logger(`rm.${funcName()} newMessageからroomにあるmemberのread_atを更新します。`)
            let mem = _room.members.find(m => m.user_id == message.owner.user_id)
            if (mem) {
                mem.last_read_at = message.owner.last_read_at
                mem.last_read_message_created_at = message.owner.last_read_message_created_at
                // mem = message.owner
                // Object.assign(mem, message.owner)
            }
        }
    },

    /**
     * ParentRoom 更新イベント を処理します。
     * ex. {"id":"5e87600a2643ec23b5314f83","status":102,"status_name":"確定済","updated_at":1586668781,"expired_at":1587891600}
     * @param data
     */
    handleWsEventForUpdateParentRoom(data: any): void {
        Logger(`<WS>WsEventForUpdateParentRoom: ${Util.output(data)}`)
        const parentId = data.id
        let room = Room.find(this.rooms, parentId, "#")
        Logger(`<WS>WsEventForUpdateParentRoom: findRoom?:${Util.isPresent(room)}`)

        if (room) {
            room.status = data.status
            room.status_name = data.status_name
            room.updated_at = data.updated_at
            room.expired_at = data.expired_at

            if (data.current_appointment) {
                // 確定した場合、appo情報が入っているので、はめます。
                room.current_appointment = Appointment.fetchFromJson([data.current_appointment])[0]
            }

            let rooms = Room.mergeRooms(this.rooms ? [...this.rooms] : [], [room])
            // 関係のあるタブにもアップデート処理を入れます。
            this.updateRoomTabs(rooms)
            if (this.currentRoom && this.currentRoom.id == room.id) {
                this.updateCurrentInfo(room)
            }
            this.refresher.needRefresh.push(`room`)
        } else {
            let is_owner_group = data.is_owner_group
            let ownerUgId = data.owner_user_group_id
            if (is_owner_group && ownerUgId != this.userInfo.user_group_id) {
                Logger(
                    `rm.${funcName()} オーナーUGだけど、現在のログインしているUGと異なる場合、権限がありませんと表示されるため取得しません。`
                )
                return
            }

            this.getRoom(parentId).then(newRoom => {
                // this.roomTabs[this.switchName][this.tabName] = newTab
                // let tab: RoomTab = this.roomTabs[`all`][`unread`]
                let rooms = Room.mergeRooms(this.rooms ? [...this.rooms] : [], [newRoom])
                let room = Room.find(this.rooms, parentId, "#")
                if (room) {
                    room.status = data.status
                    room.status_name = data.status_name
                    room.updated_at = data.updated_at
                    room.expired_at = data.expired_at
                }

                // 関係のあるタブにもアップデート処理を入れます。
                this.updateRoomTabs(rooms)
                if (this.currentRoom && this.currentRoom.id == newRoom.id) {
                    this.updateCurrentInfo(newRoom)
                }
                this.refresher.needRefresh.push(`room`)
            })
        }
    },

    /**
     * ParentRoom 公開イベント を処理します。
     * ex. {"id":"5e87600a2643ec23b5314f83","status":102,"status_name":"確定済","updated_at":1586668781,"expired_at":1587891600}
     * @param data
     */
    handleWsEventForPublishParentRoom(data: any): void {
        Logger(`<WS>WsEventForPublishParentRoom: ${Util.output(data)}`)
        const parentId = data.id

        Logger(`rm.${funcName()} pub_id: ${data.public_room_id}, ${data.status}`)
        if (data.public_room_id && data.status == 101) {
            let pubId = data.public_room_id
            Logger(`rm.${funcName()} pubId: ${pubId}`)
            let alltab: RoomTab = this.roomTabs[`all`][`public`]
            let selftab: RoomTab = this.roomTabs[`self`][`public`]
            let updatedRoomIds = []
            for (let tab of [alltab, selftab]) {
                if (tab && Util.isPresent(tab.rooms)) {
                    let _rooms = [...(tab.rooms || [])]
                    let _room = _rooms.find(r => r.id == pubId)
                    if (_room && !updatedRoomIds.includes(_room.id)) {
                        _room.rooms_voted_num += 1
                        Logger(`rm.${funcName()} 提案数をカウントアップします: ${_room.public_id}`)
                        updatedRoomIds.push(_room.id)
                    }
                    tab.rooms = _rooms
                }
            }

            if (this.currentRoom && this.currentRoom.room_type == `public_room` && this.currentRoom.id == pubId) {
                this.currentRoom.rooms_voted_num += 1
            }
        }

        let room = (this.rooms || []).find(r => r.id == parentId)
        if (room) return

        let is_owner_group = data.is_owner_group
        let ownerUgId = data.owner_user_group_id
        if (is_owner_group && ownerUgId != this.userInfo.user_group_id) {
            Logger(
                `rm.${funcName()} オーナーUGだけど、現在のログインしているUGと異なる場合、権限がありませんと表示されるため取得しません。`
            )
            return
        }

        this.getRoom(parentId).then(_room => {})
        // if (this.publishedNewRooms && this.publishedNewRooms.length > 0) {
        //     this.publishedNewRooms.push(data.public_id)
        // } else {
        //     this.publishedNewRooms = [data.public_id]
        // }
    },

    /**
     * 下書きは別管理のため、削除します。
     * @param room [Room] 削除するルーム.
     */
    deleteFromDraftTab(room: Room): void {
        let draftTab: RoomTab = this.roomTabs[`all`][`draft`]
        let selfDraftTab: RoomTab = this.roomTabs[`self`][`draft`]
        for (let _tab of [draftTab, selfDraftTab]) {
            if (_tab && Util.isPresent(_tab.rooms)) {
                if (room.status == Room.STATUS_DRAFT) {
                    // 送られてきたroomのステータスが下書きだったら下書き内容をアップデート
                    let _room = Room.find(_tab.rooms, room.id, "#")
                    if (_room) {
                        _room = Object.assign(_room, room)
                    } else {
                        _tab.rooms.unshift(room)
                    }
                } else {
                    _tab.rooms = _tab.rooms.filter(r => r.id != room.id)
                }
            }
        }
    },

    deleteFromPublicTab(room: Room): void {
        let alltab = this.roomTabs[`all`][`public`]
        let selftab = this.roomTabs[`self`][`public`]
        for (let tab of [alltab, selftab]) {
            if (tab && Util.isPresent(tab.rooms)) {
                tab.rooms = tab.rooms.filter(r => r.id != room.id)
            }
        }
    },

    setNewRoom(room: Room) {
        // ローカルデータ this.rooms 等をアップデート
        if (room.room_type == `public_room`) {
            this.deleteFromDraftTab(room)
            let _rooms = this.roomTabs[`all`][`public`].rooms
            this.roomTabs[`all`][`public`][`rooms`] = Room.mergeRooms(_rooms, [room])
            if (room.owner.user_id == this.userInfo.user_id) {
                let _selfrooms = this.roomTabs[`self`][`public`].rooms
                this.roomTabs[`self`][`public`][`rooms`] = Room.mergeRooms(_selfrooms, [room])
            }
            if (room.is_shared) {
                let _selfrooms = this.roomTabs[`self`][`shared`].rooms
                this.roomTabs[`self`][`shared`][`rooms`] = Room.mergeRooms(_selfrooms, [room])
            }
            return
        }
        this.rooms = Room.mergeRooms(this.rooms ? [...this.rooms] : [], [room])

        // 下書きから削除
        this.deleteFromDraftTab(room)

        this.updateLocalRoomsData(this.rooms)

        // すべて・調整中タブのルーム情報に追加
        const tabNameGroups = [
            [`all`, `all`],
            [`all`, `active_in_progress`],
        ]
        if (room.is_owner) {
            Array.prototype.push.apply(tabNameGroups, [
                [`self`, `all`],
                [`self`, `active_in_progress`],
            ])
        }
        tabNameGroups.forEach(a => {
            const filterName = a[0]
            const tabName = a[1]
            if (!this.roomTabs[filterName][tabName]) {
                this.roomTabs[filterName][tabName] = RoomTab.createDefault(filterName, tabName)
            }
            const tab = this.roomTabs[filterName][tabName]
            const _tab_rooms = tab.rooms ? [...tab.rooms] : []
            tab.rooms = Room.mergeRooms(_tab_rooms, [room], RoomTab.getSortFuncByTabName(tabName))
        })
    },

    fetchRoomTab(uf: string, tabname: string): RoomTab {
        let tab = this.roomTabs[uf][tabname] as RoomTab
        return tab
    },

    /**
     * 不参加カレンダーを除く全てのカレンダーを取得してきます。
     * オフした後に、astagがある場合はそれを選択した状態にします。
     */
    getAllAstags(selectedAstag: AvailableScheduleTag = null): AvailableScheduleTag[] {
        if (!this.astags) return []
        let astags = this.astags.filter(a => [1, 2, 50].includes(a.type)) || []
        astags.forEach(a => (a.selected = false))
        if (selectedAstag) {
            this.selectAstag(astags, selectedAstag)
        }
        return astags
    },
    addNewAstag(): AvailableScheduleTag {
        let astag = AvailableScheduleTag.createDefaultAstag()
        this.astags.unshift(astag)
        return astag
    },
    /**
     * 選択するastagにフラグを立てます。
     * @param astags すべてのastag この中から、astag（ない場合はastag）に選択しているフラグを立てます。
     */
    selectAstag(astags: AvailableScheduleTag[], selectedAstag: AvailableScheduleTag = null): void {
        astags.forEach(a => (a.selected = false))
        if (selectedAstag) {
            let _ast = astags.find(a => a.id == selectedAstag.id)
            if (_ast) _ast.selected = true
        }
        // if (astags.length == 0) {
        //     // 0の場合はなにもせず
        // } else if (astags.length == 1) {
        //     astags[0].selected = true
        // } else {
        //     // 複数ある場合、
        //     let _ast: AvailableScheduleTag = null
        //     if (this.astag) {
        //         _ast = astags.find(a => a.id == this.astag.id)
        //     } else if (this.defaultaAstag) {
        //         // this.astagはデフォルト設定のものが入っている
        //         _ast = astags.find(a => a.id == this.astag.id)
        //     }
        //     if (_ast) _ast.selected = true
        // }
    },
    changeDefaultAstag(astag: AvailableScheduleTag): void {
        let astags = this.getAllAstags()
        for (let _ast of astags) {
            if (_ast.type == 10) continue
            if (_ast.type == 50) continue

            if (_ast.id == astag.id) {
                this.defaultAstag = astag
                _ast.type = 2
            } else {
                _ast.type = 1
            }
        }
    },
    getAstagDetail(astagId): Promise<AvailableScheduleTag> {
        Logger(`rm.getAstagDetail astagId:${astagId}`)
        let astag
        if (astagId) {
            if (astagId == `not_attend`) {
                astag = this.chukaiAstag
                astag ||= this.astags.find(a => a.type == 10)
            } else if (astagId == `newId`) {
                // 複製した場合、すでに詳細取得後がrm.astagに入っているためそのまま返します.
                return Promise.resolve(this.astag)
            } else {
                astag = this.astags.find(a => a.id == astagId)
                if (!astag) {
                    // 仲介から取得します.
                    astag = (this.astagsTab?.shared_chukai_astags || []).find(a => a.id == astagId)
                    Logger(`rm.getAstagDetail 仲介から取得します. astag:${astag?.id}`)
                }
            }
        }

        if (astag?.has_detail) return Promise.resolve(astag)

        Logger(`rm.getAstagDetail 詳細情報をキャッシュしていないので取得してきます. astag:${astag?.id}, astagId:${astagId}`)
        let _astagId = astagId || astag?.id || `default`

        return AvailableScheduleTag.getAstagDetail(_astagId).then(_astag_dic => {
            let _astag = _astag_dic.astag
            if (_astag) {
                let _ast = (this.astags || []).find(a => a.id == _astag.id)
                if (_ast) {
                    // _ast = Object.assign(_ast, _astag)
                    _ast.has_detail = true
                    _ast.meeting_attendees_groups = _astag.meeting_attendees_groups
                } else {
                    if (_astag.owner.user_group_id == this.userInfo.user_group_id) {
                        // 外部から共有している調整カレンダーの場合はastagsに入れません.
                        this.astags.push(_astag)
                        if (_astag.type == 2) {
                            this.defaultAstag = _astag
                        }
                        if (_astag.type == 10) {
                            this.chukaiAstag = _astag
                        }
                    } else {
                        this.astagsTab?.shared_chukai_astags?.push(_astag)
                    }
                }

                if (_astag?.is_shared) {
                    // 共有を受けているカレンダーのオーナーのloctagsを保持します.
                    this.sharedLocationsDic[_astag.id] = _astag_dic.shared_loctags || []
                }

                return _astag
            }

            return null
        })
    },
    canUseOption(optionName: StringUnitLength, setNameIfUnable: boolean = false): boolean {
        let available = false
        if (!this.plan_option) {
            Logger(`rm.${funcName()} plan_optionを取得し直します.`)
            this.getAvailableSchedule().then(data => {
                available = PlanOption.canUseOption(this.plan_option, optionName)
                if (setNameIfUnable) {
                    if (available) {
                        this.showPlanOptionModalWithName = null
                    } else {
                        this.showPlanOptionModalWithName = optionName
                    }
                }
                return available
            })
        } else {
            available = PlanOption.canUseOption(this.plan_option, optionName)
            Logger(`rm.canUseOption setNameIfUnable:${setNameIfUnable} optionName:${optionName} available:${available}`)
            if (setNameIfUnable) {
                if (available) {
                    this.showPlanOptionModalWithName = null
                } else {
                    this.showPlanOptionModalWithName = optionName
                }
            }
            return available
        }
    },
    /**
     * アドレス帳を取得してきます。
     */
    getAddressBooks(wantNext: boolean): Promise<RoomMember[]> {
        if (this.address_book && this.address_book.contact_lists && !wantNext)
            return Promise.resolve(this.address_book.contact_lists)

        if (!this.address_book) {
            this.address_book = AddressBook.createDefault()
        }

        return AddressBook.getContactList(this.address_book).then(data => {
            if (data) {
                this.address_book = data
                // this.address_book.contact_lists = data.contact_lists
                // this.address_book.contact_list_groups = data.contact_list_groups
                // this.address_book.ug_members = data.ug_members
            } else {
                this.address_book.contact_lists = []
                this.address_book.contact_list_groups = []
                this.address_book.ug_members = []
            }
            return this.address_book.contact_lists
        })
    },
    getContactListGroups(): Promise<ContactListGroup[]> {
        if (this.address_book && this.address_book.contact_list_groups)
            return Promise.resolve(this.address_book.contact_list_groups)

        return this.getAddressBooks(true).then(_ => {
            return this.address_book.contact_list_groups
        })
    },
    getTemplates(): Promise<MessageTemplate[]> {
        Logger(`rm.getTemplates isGuest:${Util.isGuest()}`)
        if (Util.isGuest()) return Promise.resolve([])
        if (this.templates) return Promise.resolve(this.templates)

        return MessageTemplate.getTemplates().then(tmps => {
            if (tmps) {
                this.templates = tmps
            }
            return tmps
        })
    },
    getTitles(): Promise<string[]> {
        if (this.titles) return Promise.resolve(this.titles)

        return Appointment.getTitles().then(titles => {
            this.titles = titles
            return titles
        })
    },
    getUgMembers(): Promise<RoomMember[]> {
        if (this.address_book && this.address_book.ug_members) return Promise.resolve(this.address_book.ug_members)

        return this.getAddressBooks(true).then(_ => {
            return this.address_book.ug_members
        })
    },
    getLocations(): any {
        if (this.loctags) return Promise.resolve(this.loctags)

        return LocationTag.getLocations().then(e => {
            Logger(`rm.${funcName()} 登録済みのロケーション情報を取得してきました: ${Util.output(e)} `)
            this.loctags = e || []
            return this.loctags
        })
    },
    getLocationNames(): Promise<string[]> {
        if (this.locationNames) return Promise.resolve(this.locationNames)

        return Appointment.getLocationNames().then(e => {
            Logger(`rm.${funcName()} 登録済みのロケーション情報(Names)を取得してきました: ${Util.output(e)} `)
            this.locationNames = e || []
            return this.locationNames
        })
    },
    getGroupTags(): Promise<GroupTag[]> {
        if (this.groupTags) return Promise.resolve(this.groupTags)

        return GroupTag.getGroupTags().then(e => {
            Logger(`rm.${funcName()} 登録済みのグループタグを取得してきました: ${Util.output(e)} `)
            this.groupTags = e || []
            return this.groupTags
        })
    },
    findUserGroupSetting() {
        let org = this.organization
        if (!this.org) return null
        let ugs = org.user_groups
        if (Util.isPresent(ugs)) {
            let ug = ugs[0]
            if (Util.isPresent(ug)) {
                return ug.user_group_setting
            }
        }
        return null
    },
    startProgress() {
        let prog = useProgress().start()
        this.progresses.push(prog)
    },
    endProgress() {
        this.progresses.pop()?.finish()
    },
    isProgressing() {
        return this.progresses.length > 0
    },
    updateAstagsInfoWithTab(tab: AstagsTab) {
        this.astags = tab.astags
        this.shared_astags = tab.shared_astags
        this.astagsTab = tab
    },
    /**
     * 仲介招待UG(ug.status==2000)の場合、リダイレクト制御を行います.
     */
    redirectTopIfChukaiFreePlan(redirectName: string = null) {
        if (Const.chukaiFreePlan(this)) {
            Logger(`rm.redirectTopIfChukaiFreePlan ChukaiFreePlanのため、トップにリダイレクトします。`)
            if (redirectName) {
                router.push({ name: redirectName })
            } else {
                router.push({ name: "root" })
            }
        }
    },

    getAllAssignForms() {
        if (this.aforms) return Promise.resolve(this.aforms)

        return AssignForm.getAllAssignForms().then(forms => {
            this.aforms = forms
            return forms
        })
    },
    getAformDetail(aformId: string): Promise<AssignForm> {
        if (!aformId) return Promise.resolve(null)
        if (this.aform && this.aform.id == aformId) return Promise.resolve(this.aform)

        return AssignForm.getAformDetail(aformId).then(aform => {
            if (aform) {
                let _fm = (this.aforms || []).find(a => a.id == aform.id)
                if (_fm) {
                    _fm.has_detail = true
                    _fm.assign_conditions = aform.assign_conditions
                } else {
                    if (aform.owner.user_group_id == this.userInfo.user_group_id) {
                        // 外部から共有している調整カレンダーの場合はastagsに入れません.
                        this.aforms ||= []
                        this.aforms.push(aform)
                    }
                }
                this.aform = aform
            }
            return aform
        })
    },
    designType() {
        Logger(`rm.designType design_type:${this.currentRoom?.room_setting?.design_type}`)
        if (Util.isPreview()) {
            return `normal`
        }
        let designType = this.currentRoom?.room_setting?.design_type
        if (!designType) return
        // normal / seminar
        return designType || `normal`
    },
    getNotifications(): Promise<any> {
        if (this.notifications) return Promise.resolve(this.notifications)

        return CustomNotification.get(`user`).then(dic => {
            this.notifications = dic.notification_dic
            this.noti_available_events = dic.available_events
            return dic.notification_dic
        })
    },
})
export default _RoomManager
